import React, {useState} from "react";
import {Button, Input, Space} from 'antd';
import SectionWrapper from "../../layout2/components/SectionWrapper";
import '../../layout2/styles/statistictablefilters.css';
import {layout2} from "../../../../utils/styles";
import {CheckOutlined, CloseCircleOutlined} from "@ant-design/icons";



const GradeItemForm = ({name, setFormVisible, stateGradeName, setGradeName, onClickSaveBtn}) => {

  return (
    <div style={{display: 'flex'}}>
      <Button
        type={'danger'}
        style={{flexShrink: 0}}
        icon={<CloseCircleOutlined />}
        onClick={() => {
          setFormVisible(false);
          setGradeName('');
        }}
      />
      <Input
        defaultValue={name}
        onChange={(e) => { setGradeName(e.target.value) }}
      />
      <Button style={{flexShrink: 0}} disabled={!stateGradeName} icon={<CheckOutlined />} type={'primary'} onClick={onClickSaveBtn}/>
    </div>
  )
}


const GradeItem = ({grade, removeClassroom, updateClassroom}) => {
  const [gradeName, setGradeName] = useState(grade.name);
  const [editMode, setEditMode] = useState(false);


  const updateClassroomTitle = () => {
    updateClassroom({clsroomId: grade.id, newTitle: gradeName})
    setEditMode(false);
  };

  return (
    <Space style={{display: 'flex'}}>
      {
        editMode ? (
          <GradeItemForm
            name={grade.name}
            stateGradeName={gradeName}
            setFormVisible={setEditMode}
            setGradeName={setGradeName}
            onClickSaveBtn={updateClassroomTitle}
          />
        ) : (
          <>
            <div className={'radio-btn'} style={{margin: '10px 5px 7px 0', fontFamily: 'Roboto-Bold'}}> {grade.name} </div>
            <Button
              type={'link'}
              className={'withUnderline'}
              style={{color: layout2.gray, paddingInline: '5px', fontSize: '16px'}}
              onClick={() => setEditMode(true)}
            >
              Переименовать
            </Button>
            <Button
              type={'link'}
              className={'withUnderline'}
              danger
              style={{paddingInline: '5px', fontSize: '16px'}}
              onClick={() => removeClassroom(grade.id)}
            >Удалить</Button>
          </>
        )
      }
    </Space>
  )
}


const GradesForm = (props) => {
  const [gradeName, setGradeName] = useState('');
  const [formVisible, setFormVisible] = useState(false);

  const addNewClassroom = () => {
    props.addNewClassroom(gradeName)
    setFormVisible(false);
    setGradeName('');
  };

  return (
    <SectionWrapper title={'Классы'} loading={props.loading}>
      <div className={'radio-group'} style={{marginBottom: '15px'}}>
        {
          props.grades.map((grade) => {
            return (
              <GradeItem
                key={'grade-' + grade.id}
                grade={grade}
                removeClassroom={props.removeClassroom}
                updateClassroom={props.updateClassroom}
              />
            )
          })
        }
      </div>

      {
        formVisible ? (
          <div style={{margin: '20px 0 10px', maxWidth: '300px'}}>
            <GradeItemForm
              name={gradeName}
              stateGradeName={gradeName}
              setFormVisible={setFormVisible}
              setGradeName={setGradeName}
              onClickSaveBtn={addNewClassroom}
            />
          </div>
        ) : (
          <>
            {
              !props.grades.length && (
                <h4 style={{color: layout2.gray, fontSize: '18px', fontFamily: 'Roboto-Bold'}}>
                  Вы еще не добавили ни одного класса
                </h4>
              )
            }
            <Button
              type={'link'}
              className={'withUnderline'}
              style={{fontSize: '16px'}}
              onClick={() => setFormVisible(true)}
            >
              + добавить класс
            </Button>
          </>
        )
      }
    </SectionWrapper>
  )
}

export default GradesForm;