import {Button, Modal, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import '../styles/modals.css';
import {QuestionCircleOutlined} from '@ant-design/icons';
import popupImage from '../../../../platformImages/popups/copy_link_popup_image.png';
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {ymUseHomeworkFunctionalityGoal} from "../../../../metriks/ym_metriks";
import {_t} from "../../../../utils/lang/common";
import {CopyToClipboard} from "react-copy-to-clipboard";

const PopupForHomeworkCopyLink = (props) => {
  const [copiedLink, setCopiedLink] = useState(false);

  // Hide a success text after copy link
  useEffect(() => {
    setTimeout(() => {
      if (copiedLink) { setCopiedLink(false) }
    }, 1500)
  }, [copiedLink]);


  return (
    <Modal
      title={null}
      centered
      style={{margin: 0, padding: 0}}
      visible={props.homeworkCopyLinkPopupVisible}
      onCancel={() => props.setHomeworkCopyLinkPopupVisible(false)}
      width={505}
      wrapClassName={'modal-common popup-for-homework-copy-link'}
      footer={null}
    >
      <div className={'head-form'}>
        <img src={popupImage}/>
      </div>
      <div className={'modal-body'}>
        <div className={'step-item'}>
          <p className={'step-item__title'}>
            <span>Шаг 1:</span>
            Скопируйте ссылку для учеников, чтобы они вошли в это задание:

            {/* Todo add video instruction */}
            {/*<QuestionCircleOutlined style={{fontSize: '20px', position: 'relative', margin: '0 5px', top: '3px', cursor: 'pointer'}}/>*/}
          </p>
          <div className={'step-item__content'}>
            <p className={'link'}>{props.link || '-'}</p>

            <Tooltip
              title={_t("copy_success")}
              color={'green'}
              trigger={'click'}
              placement="top"
              visible={copiedLink}
            >
              <CopyToClipboard
                text={`${window.location.origin}/homework-auth/${props.selectedHomework.code}`}
                onCopy={() => {
                  setCopiedLink(true);
                  ymUseHomeworkFunctionalityGoal(props.selectedHomework.code, props.selectedHomework.tasks?.length);
                }}
              >
                <Button size={'large'} type={'primary'} className={'copy_link'}>
                  {_t("button_copy_lesson_link")}
                </Button>
              </CopyToClipboard>
            </Tooltip>
          </div>
        </div>
        <div className={'step-item'}>
          <p className={'step-item__title'}>
            <span>Шаг 2:</span>
            Вставьте ссылку в запись электронного дневника, в общий чат в социальной
            сети или в электронное письмо для класса
          </p>
        </div>
        <div className={'step-item'} style={{marginBottom: 0}}>
          <p className={'step-item__title'}>
            <span>Шаг 3:</span>
            Каждый ученик, нажавший на ссылку перейдёт в окошко с данной проверочной работой.
            А его выполнение будет фиксироваться в таблице в нижней части этой страницы
          </p>
        </div>
      </div>
    </Modal>
  )
}



const mapStateToProps = state => ({
  homeworkCopyLinkPopupVisible: state.commonReducer.homeworkCopyLinkPopupVisible,
});
const mapDispatchToProps = (dispatch, {match}) => ({
  setHomeworkCopyLinkPopupVisible: (visible) => dispatch(actions.setHomeworkCopyLinkPopupVisible(visible))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PopupForHomeworkCopyLink);
