import React, {useEffect, useState} from "react";
import ResultBar from "../components/ResultBar";
import '../styles/mainContainer.css';
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import HealthBar from "../components/HealthBar";
import HealthModal from "../components/HealthModal";


const TaskMainContainer = (props) => {
  // const defaultHealth = Math.ceil(props.tasksCount * .5);
  const defaultHealth = props.health || props.tasksCount;
  const [health, setHealth] = useState(defaultHealth);
  const [healthModalVisible, setHealthModalVisible] = useState(false);


  const receiveMessage = (e) => {
    if (e.data === 'school-task-fail') {
      setHealth(prevState => prevState-1);
      setHealthModalVisible(true);

      setTimeout(() => {
          setHealthModalVisible(false);
      }, 1500);
    }
  }

  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
    return () => {
      window.removeEventListener("message", receiveMessage);
    }
  }, [])

  useEffect(() => {
    props.addLessonResult(props.lessonCode, false, {}, true);
  }, []);
  useEffect(() => {
    if (props.taskComplete) {
      window.postMessage('taskSuccess');
      props.addLessonResult(props.lessonCode, true, {}, false);
    }
  }, [props.taskComplete]);

  return (
    <div className={'main-container'}>
      <HealthBar defaultHealth={defaultHealth} healthCount={health}/>
      <ResultBar
        tasksCount={props.tasksCount}
        completeCount={props.completeCount}
      />

      {props.children}


      <HealthModal
        lang={'ru'}
        restart={health === 0 || health === defaultHealth}
        visible={health === 0 || healthModalVisible}
        onClickReset={() => {
          setHealth(defaultHealth);
          setHealthModalVisible(false);
          props.setCompletedTaskCount(0);
        }}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return ({});
}
const mapDispatchToProps = (dispatch, {match}) => ({
  addLessonResult: (lesson_code, success, detailed, create_new) => dispatch(actions.addLessonResult(lesson_code, success, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskMainContainer);
