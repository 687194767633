import React, {useEffect, useState} from "react";
import LessonItem from "../../layout2/components/LessonItem";
import SectionWrapper from "../../layout2/components/SectionWrapper";
import {Spin, Button} from "antd";
import {connect} from "react-redux";
import StatisticTable from "../../layout2/components/StatisticTable";
import {hwColumnsConverter, hwRowsConverter} from "../utils/converters";
import {StatisticTableFilter} from "../../layout2/components/StatisticTableFilter";
import {getConfigFilter} from "../utils/helper";
import * as actions from "../../../../store/actions";
import useSetInterval from "../../../../utils/custom-hooks/useSetInterval";
import {TaskListWrapper} from "../../layout2/components/Wrappers";
import HwMenu from "./HwMenu";
import addTaskImg from '../../../../images/add_task_to_homework.png';
import {layout2} from "../../../../utils/styles";
import {PlusSquareOutlined} from '@ant-design/icons';
import HomeworkStudentsAchievements from "./HomeworkStudentsAchievements";
import useMedia from "../../../../utils/custom-hooks/useMedia";



const TeacherHwTasks = (props) => {
  const {
    user,
    mainLoading,
    classrooms,
    corrLessons,
    fetchExecutionStatistics,
    homeworkNotifications
  } = props;
  const [loading, setLoading] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [stateFilter, setStateFilter] = useState(
    {
      studentsClassroomFilter: [],
      rowsFiller: null,
      measureFilter: null,
    }
  );

  useSetInterval(() => fetchStatistic(true), 10000, true);

  const isMobileMode = useMedia(['(max-width: 600px)'],[true], false);

  let tableColumns = hwColumnsConverter(corrLessons, stateFilter, isMobileMode);
  let tableRows = hwRowsConverter(tableData);

  useEffect(() => {
    reload();
  }, [stateFilter])

  const fetchStatistic = async (withoutLoading) => {
    setLoading(!withoutLoading);
    const resp = await fetchExecutionStatistics(props.selectedHomework?.id, stateFilter);
    setTableData(resp?.results || [])
    setLoading(false);
  }

  const reload = async () => {
    setLoading(true);
    await props.refreshData();
    await fetchStatistic();
    setLoading(false);
  }


  const classesWithoutEmpty = classrooms.filter( cls => !cls.for_new_students);
  const configFilter = getConfigFilter(classesWithoutEmpty, setStateFilter);

  const style = {color: layout2.darkBlue, fontSize: '15px', fontWeight:' bold', lineHeight: 1, margin: '60px 0 40px'};
  return (
    <div className={'teacher-content-wrapper lessons--new-grid'}>
      <TaskListWrapper loading={mainLoading && !corrLessons.length}>
        <HwMenu
          loading={mainLoading}
          selectedHomework={props.selectedHomework}
          setSelectedHomeworkId={props.setSelectedHomeworkId}
        />

        <div className={'lessonsContainer'}>
          {
            props.visible ? (
              <>
                {
                  corrLessons.map((lsn, i) => {
                    return (
                      <React.Fragment key={'lesson-td' + lsn.id}>
                        <LessonItem
                          newCardStyle={true}
                          lessonItem={lsn}
                          selectedLesson={props.selectedLesson}
                          setSelectedLesson={props.setSelectedLesson}
                          isTeacherHwPage={true}
                          user={user}
                          teacherPage={true}
                          selectedHwId={props.selectedHomework?.id}
                          withoutHwTags={true}
                        />
                      </React.Fragment>
                    )
                  })
                }
              </>
            ) : (
              <div style={{margin: '60px 0px 40px'}}>
                <Spin spinning={mainLoading} size={'large'}>
                  <span style={style}>
                    Перейдите в <a href={'/tasks'}> каталог </a> и нажмите
                    <PlusSquareOutlined style={{margin: '0 10px'}} />
                    , чтобы добавить задачу
                  </span>
                  <img style={{display: 'block', margin: '0 0 0 15px'}} src={addTaskImg}/>
                </Spin>
              </div>
            )
          }
        </div>
      </TaskListWrapper>

      <HomeworkStudentsAchievements />

      {
        props.visible ? (
          <SectionWrapper
            wrapperClassname={'sectionWrapper'}
            loading={loading}
            titleContainerStyle={{marginTop: '0'}}
            title={'Статистика по выполнению'}
            btnAfterTitle={
              <Button
                type={'link'}
                className={'withUnderline'}
                onClick={reload}
              > Обновить </Button>
            }
          >
            {/* --- Filters --- */}
            <StatisticTableFilter
              configFilter={configFilter}
              stateFilter={stateFilter}
            />

            <StatisticTable
              rows={tableRows}
              columns={tableColumns}
              isMobileMode={isMobileMode}
            />
          </SectionWrapper>
         ): null
      }

      <a
        style={{padding: '0 65px 70px', display: 'block', maxWidth: '740px', width: '100%'}}
        href="https://inventic.tech/onboarding" target={'blank'}
      >
        Как создавать и отправлять ученикам задания с автопроверкой?
      </a>
  </div>)
}



const mapStateToProps = (state) => {
  return {
    user: state.lessonsReducer.user,
    homeworkNotifications: state.lessonsReducer.homeworkNotifications,
  }
};

const mapDispatchToProps = (dispatch) => ({
  fetchExecutionStatistics: (selectedHwId, filterState) => dispatch(actions.fetchExecutionStatistics(selectedHwId, filterState))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeacherHwTasks);
