import React, {useEffect} from "react";
import '../styles/styles.css'
import tryAgain from '../images/tryAgain.png';
import tryAgainRu from '../images/tryAgainRu.png';
import CustomButton from "./CustomButton";
import { isEng } from "../../../../utils/lang/common";


/**
 * PROPS
 *  bgImg: string,
 *  title: string,
 *  tryAgain: bool,
 *  success: bool,
 *  continueClassName: string
 *  contentClassName: string
*/

export const SchoolTaskContainer = (props) => {

  useEffect(() => {
    if (props.failMessage) {
      window.postMessage('school-task-fail');
    }
  }, [props.failMessage])
  return (
    <div className={`school-tasks school-tasks__container`} style={{backgroundImage: `url(${props.bgImg})`}}>
      <div className={'school-tasks__head'}>
        <p className={'school-tasks__title'} style={{fontSize: props.titleFontSize}}>{props.title}</p>
      </div>
      <div className={`school-tasks__content ${props.contentClassName || ''}`}>
        {props.children}
        {
          props.success ? (
            <CustomButton
              className={'continue-btn ' + props.continueClassName}
              onClick={props.onClickContinue}
              btnText={isEng() ? 'Press to continue' : 'Далее'}
            />
          ):null
        }
      </div>
      {
        props.tryAgain ? (
          <img
            src={isEng() ? tryAgain : tryAgainRu}
            className={'school-tasks__try-again'}
            onClick={props.onClickRestart}
          />
        ):null
      }
    </div>
  )
}

export default SchoolTaskContainer;
