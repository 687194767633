import axios from 'axios';
import {
  ACCESS,
  CLASSROOM,
  CLASSROOMS_LIST,
  CLOSE_LESSON_SESSION,
  CREATE_LESSON_LINK,
  CREATE_PRESENTATION,
  CURRENT_USER,
  FEEDBACK,
  FORGOT_PASSWORD,
  HOMEWORK,
  HOMEWORK_AUTH,
  HOMEWORK_COMMON_DYNAMICS_STATISTICS,
  HOMEWORK_COMMON_PROGRESS_STATISTICS,
  HOMEWORK_EXECUTION_STATISTICS,
  HOMEWORK_STATS,
  HOMEWORKS_BY_PERIOD,
  HW_TEACHER_NOTIFICATIONS,
  LESSON,
  LESSON_ACTIVITY_LOG,
  LESSON_CATALOG,
  LESSON_RESULT,
  LESSON_SESSION_AUTH,
  LESSONS_GROUPS,
  LOGIN,
  LOGOUT,
  MAIN_PAGE,
  NEWS_FEED,
  PRESENTATION,
  REGISTRATION, SCHOOL_TASK,
  TEACHER_CARD,
  TEXT_PROBLEM_RESULT_CLEANER,
  TEXT_PROBLEMS_CONTAINER,
} from '../urls'
import i18next from "i18next";
import {openNotificationWithIcon, userRoles} from "../../utils/common";
import moment from "moment";
import {generateGetParamsByObj, redirectToLogin} from "../../utils/api";
import {getDateByPeriod} from "../../views/pages/statistic/utils";
import {ymUserParams} from "../../metriks/ym_metriks";
import {
  changeFailureVisible,
  changeSuccessVisible,
  setCongratulationTeacherPopupVisible,
  setDarkMode
} from "./commonActions";
import {resetAuthReducer} from "./authActions";


export const sendFeedback = (data) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.post(FEEDBACK, data);
  } catch (error) {
    console.log('debug error', error);
    openNotificationWithIcon('error', 'Произошла ошибка при отправке обратной связи');
  }
  return resp;
};

export const fetchNews = (link) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.get(`${link ? link : NEWS_FEED}`);
  } catch (error) {
    console.log('debug error', error);
    openNotificationWithIcon('error', 'Произошла ошибка при получении новостей');
  }
  return resp;
};

export const fetchSchoolTask = (containerId) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.get(`${SCHOOL_TASK}`);
  } catch (error) {
    console.log('debug error', error);
    openNotificationWithIcon('error', 'Произошла ошибка');
  }
  return resp;
};
export const fetchLesson = (lessonCode) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.get(`${LESSON}?lsnCode=${lessonCode}`);
  } catch (error) {
    console.log('debug error', error);
    openNotificationWithIcon('error', 'Произошла ошибка');
  }
  return resp;
};

export const createPresentation = (formdata) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.post(`${CREATE_PRESENTATION}`, formdata);
  } catch (error) {
    console.log('debug error', error);
    openNotificationWithIcon('error', 'Произошла ошибка');
  }
  if (resp && resp.status) {
    dispatch(setPresentationData(resp.allPresentationsSlides, resp.allPresentations));
    openNotificationWithIcon('success', 'Презентация успешно создана');
  }
  return resp;
};


export const lessonSessionAuth = (teacherCode) => async (dispatch, getState) => {
  let resp;
  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');

  try {
    resp = await axios.post(`${LESSON_SESSION_AUTH}`, {firstName, lastName, teacher_code: teacherCode});
  } catch (error) {
    console.log('debug closeLesson error', error);
  }

  if (resp && resp.status) {
    dispatch(setTeacherProfile(resp.teacherProfile));
    window.location = '/main';
  }
  return resp;
};

export const closeLesson = () => async (dispatch, getState) => {
  dispatch(changeCreateLessonSpinnerVisible(true));
  let resp;
  const currentState = getState();
  const lessonSession = currentState.lessonsReducer.lessonSession;
  try {
    resp = await axios.post(`${CLOSE_LESSON_SESSION}`, {token: lessonSession.token});
  } catch (error) {
    console.log('debug closeLesson error', error);
  }

  if (resp && resp.status) {
    dispatch(resetLessonSession());
    dispatch(changeLessonSession({
      zoomLink: lessonSession.zoomLink,
      selectedLessons: lessonSession.selectedLessons,
      startLessonDatetime: moment(lessonSession.startLessonDatetime),
    }));
    dispatch(updateLessonsAndGroups(resp.lessonsGroups, resp.chapters, resp.lessons));
  }
  dispatch(changeCreateLessonSpinnerVisible(false));
  return resp;
};

export const generateLessonLink = () => async (dispatch, getState) => {
  dispatch(changeCreateLessonSpinnerVisible(true));
  let resp;
  const currentState = getState();
  const lessonSession = currentState.lessonsReducer.lessonSession;
  const selectedLessons = lessonSession.selectedLessons;
  const lessonsCodes = Object.keys(selectedLessons).filter((k) => selectedLessons[k] === true);
  try {
    resp = await axios.post(`${CREATE_LESSON_LINK}`, {
      lessonsCodes: lessonsCodes,
      zoomLink: lessonSession.zoomLink,
      startLessonDatetime: moment(lessonSession.startLessonDatetime, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
    });
  } catch (error) {
    console.log('debug generateLessonLink error', error);
  }
  if (resp && resp.status) {
    const sessionOfLesson = resp.session_of_lesson;
    dispatch(updateLessonsAndGroups(resp.lessonsGroups, resp.chapters, resp.lessons));
    dispatch(changeLessonSession({
      link: sessionOfLesson.url,
      token: sessionOfLesson.code,
      zoomLink: sessionOfLesson.zoom_link,
      startLessonDatetime: moment(sessionOfLesson.start_datetime).format('DD:MM:YYYY HH:mm')
    }));
  }
  dispatch(changeCreateLessonSpinnerVisible(false));
  return resp;
};


export const setLanguage = (language) => async (dispatch, getState) => {
  const correctLanguage = language ? language : 'ru';
  await i18next.init({
    lng: correctLanguage,
    resources: require(`../../utils/lang/${correctLanguage}.json`)
  });
  localStorage.setItem('lang', language);
  dispatch(changeLanguage(correctLanguage));
  return correctLanguage;
};


// -------------------- ACTIVITY LOGS --------------------

export const sendLessonActivityLog = () => async (dispatch, getState) => {
  const currentState = getState();
  const lessonsReducer = currentState.lessonsReducer;
  const user = lessonsReducer.user;
  if (user) {
    dispatch(setLessonActivityLog());
    dispatch(getLessonActivityLog());
  }
};

export const setLessonActivityLog = () => async (dispatch, getState) => {
  let resp;
  const currentState = getState();
  const lessonsReducer = currentState.lessonsReducer;
  const presentation = currentState.lessonsReducer.presentation;
  const token = lessonsReducer.lessonSession.token;
  const activeLesson = lessonsReducer.activeLesson;
  resp = await axios.post(LESSON_ACTIVITY_LOG, {
    "token": token,
    "activeLessonId": activeLesson.id,
    "startTime": activeLesson.start_time,
    "finishTime": activeLesson.finish_time,
    "presentation": {
      isPresentationOpen: Boolean(presentation) && presentation.visibleToStudents,
      activeImg: Boolean(presentation) ? presentation.activeImgId : null
    }
  });
  if (resp && resp.status) {
    if (lessonsReducer.user.role === userRoles.student) {
      dispatch(changeLessonSession({token: resp.token}));
      dispatch(setLessonsAccess(resp.lessonsAccess));
    }
    if (lessonsReducer.user.role === userRoles.teacher)
      dispatch(setPresentation(resp.presentationLog));
  }
  return resp;
};

export const getLessonActivityLog = () => async (dispatch, getState) => {
  let resp;
  const currentState = getState();
  const lessonsReducer = currentState.lessonsReducer;
  const teacherCode = lessonsReducer.teacherProfile.code;
  const token = lessonsReducer.lessonSession.token;

  const correctUrl = `${LESSON_ACTIVITY_LOG}?teacherCode=${teacherCode}&token=${token}`;
  resp = await axios.get(correctUrl);

  if (resp && resp.status) {
    if (lessonsReducer.user.role === userRoles.teacher) {
      dispatch(changeLessonSession({
        studentsLessonsAccess: resp.studentsLessonsAccess,
        students: resp.students
      }));
    }
    if (lessonsReducer.user.role === userRoles.student) {
      dispatch(setPresentation(resp.presentationLog));

      if (resp.teacherActiveLesson) {
        dispatch(setActiveLesson(resp.teacherActiveLesson));
      }
    }
  }
  return resp;
};


// ------------------------------------------------------------------

export const fetchCurrentUser = () => async (dispatch, getState) => {
  let resp;

  try {
    resp = await axios.get(CURRENT_USER);
  } catch (error) {
    console.log('debug user error', error);
  }
  let user;
  if (resp && resp.status) {
    dispatch(setUserWithMode(resp.user, resp.profile));
  }

  ymUserParams({
    name: user ? `${user.first_name} ${user.last_name}` : '',
    email: user ? `${user.email}` : '',
    UserID: user ? user.id : 0,
  });

  return user
}

export const fetchMainPageData = (isDemo, lsnId) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.get(`${MAIN_PAGE}?demo=${+Boolean(isDemo)}`);
  } catch (error) {
    console.log('debug main page error', error);
  }

  if (resp && resp.status) {
    dispatch(updateLessonsAndGroups(resp.lessonsGroups, resp.chapters, resp.lessons, lsnId));
    dispatch(setLessonsAccess(resp.lessonsAccess));
    dispatch(setTeacherProfile(resp.teacherProfile));
    dispatch(setUserWithMode(resp.user, resp.profile));
    dispatch(setVideos(resp.allVideos));

    if (resp.lessonSession) {
      dispatch(setLessonSession(resp));
    }

    // ----- PRESENTATION -----
    dispatch(setPresentation(resp.presentation));
    if (resp.allPresentations.length) {
      dispatch(setPresentationData(resp.allPresentationsSlides, resp.allPresentations));
      dispatch(changeActivePresentation(resp.allPresentations[0].id));
    }
  }
  return resp
};

const setLessonSession = (resp) => async (dispatch, getState) => {
  const lessonSession = resp.lessonSession;
  const lessonsOfSessionCodes = resp.lessonsOfSessionCodes;
  const selectedLessons = resp.lessons.reduce((obj, lesson) => {
    const checked = lessonsOfSessionCodes.includes(lesson.code);
    return (obj[lesson.code] = checked, obj)
  }, {});

  if (lessonSession)
    dispatch(changeLessonSession({
      link: lessonSession.url,
      token: lessonSession.code,
      startLessonDatetime: moment(lessonSession.start_datetime).format('DD:MM:YYYY HH:mm'),
      zoomLink: lessonSession.zoom_link,
      selectedLessons: selectedLessons,
      students: resp.studentsOfLessonSession,
    }));
  return true;
};

export const forgotPassword = (email) => async (dispatch, getState) => {
  let resp;
  if (email) {
    try {
      resp = await axios.post(`${FORGOT_PASSWORD}`, {email});
    } catch (error) {
      console.log('debug forgotPassword error', error);
    }
    return resp
  }
};
export const changePassword = (password, code) => async (dispatch, getState) => {
  let resp;
  if (password && code) {
    try {
      resp = await axios.patch(`${FORGOT_PASSWORD}`, {password, code});
    } catch (error) {
      console.log('debug changePassword error', error);
    }
    if (resp?.status) {
      dispatch(setUserWithMode(resp.user, resp.profile));
    }
    return resp && resp.error
  }
};

export const loginAction = (loginData) => async (dispatch, getState) => {
  let resp;
  if (loginData) {
    try {
      resp = await axios.post(`${LOGIN}`, loginData);
    } catch (error) {
      console.log('debug login error', error);
    }
    if (resp?.status) {
      dispatch(resetAuthReducer());
      dispatch(setUserWithMode(resp.user, resp.profile));
    }
  }
  return resp
};

export const logoutAction = () => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.get(`${LOGOUT}`);
  } catch (error) {
    console.log('debug logout error', error);
  }

  if (resp && resp.status) {
    dispatch(resetToInit())
  }
  return resp
};

export const registrationAction = () => async (dispatch, getState) => {
  const currentState = getState();
  const registrationData = {
    name: currentState.authReducer.name,
    lastName: currentState.authReducer.lastName,
    isTeacher: currentState.authReducer.isTeacher,
    email: currentState.authReducer.email,
    password: currentState.authReducer.password,
    phone: currentState.authReducer.phone,
    // repeatPassword: currentState.authReducer.repeatPassword,
  }
  let resp;
  if (registrationData) {
    try {
      resp = await axios.post(`${REGISTRATION}`, registrationData);
    } catch (error) {
      console.log('debug error', error);
    }
    if (resp?.status) {
      dispatch(resetAuthReducer());

      dispatch(setUserWithMode(resp.user, resp.profile));
      if (resp.profile.role === userRoles.teacher) {
        dispatch(setCongratulationTeacherPopupVisible(true));
      }
    }
  }
  return resp
};

export const checkLogin = (redirectCallback, withoutRedirect=false) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.get(`${LOGIN}`);
  } catch (error) {
    console.log('debug logout error', error);
  }

  if (resp && !resp.status && window.location !== '/' && !withoutRedirect) {
    if (!redirectCallback) {
      redirectToLogin();
    } else {
      redirectCallback({mode: 'std'});
    }
  }
  return resp
};


export const updateLessonStatus = (status, lessonId, method) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.patch(`${ACCESS}`, {status, lesson_id: lessonId, method});
  } catch (error) {
    console.log('debug updateStatus error', error);
  }

  if (resp && resp.status) {
    dispatch(setLessonsAccess(resp.lessonsAccess));
  }
  return resp
};


export const openLesson = (lesson) => async (dispatch, getState) => {
  dispatch(setActiveLesson(lesson));
  dispatch(changeFailureVisible(false));
  dispatch(changeSuccessVisible(false));
};

export const updateLessonsAndGroups = (lessonsGroups, chapters, lessons, lsnId) => async (dispatch, getState) => {
  if (lessonsGroups && lessons) {
    const correctGroupPriority = Math.min(...lessonsGroups.map(group => group.priority));
    const correctActiveLesson = lessons.find((lesson) => {
      if (lsnId) {
        return (lesson.id === lsnId);
      } else {
        const firstGroup = lessonsGroups.find((group) => group.priority === correctGroupPriority);
        if (firstGroup) {
          const correctLsnPriority = Math.min(...lessons.filter(lsn => lsn.group === firstGroup.id).map(lsn => lsn.priority)) ?? 1;
          return (lesson.group === firstGroup.id) && lesson.priority === correctLsnPriority;
        }
      }
    });

    if (correctActiveLesson) {
      dispatch(setActiveLesson(correctActiveLesson));
    }
    dispatch(setLessons(lessons));
    dispatch(setLessonsGroups(lessonsGroups));
    dispatch(setChapters(chapters));
  }
};


export const addNewClassroom = (classroomTitle) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.post(`${CLASSROOM}`, {classroomTitle});
  } catch (error) {
    console.log('debug classrooms list error', error);
  }
  return resp
};
export const updateClassroom = ({clsroomId, newName, studentId}) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.patch(`${CLASSROOM}`, {clsroomId, newName, studentId});
  } catch (error) {
    console.log('debug classrooms list error', error);
  }
  return resp
};
export const removeClassroomMethod = (clsroomId, studentId) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.delete(`${CLASSROOM}`, {data: {classroomId: clsroomId, studentId: studentId}});
  } catch (error) {
    console.log('debug classrooms list error', error);
  }
  return resp
};
export const fetchClassroomsData = (isTeacher) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.get(`${CLASSROOMS_LIST}`);
  } catch (error) {
    console.log('debug classrooms list error', error);
  }
  return resp
};


export const fetchExecutionStatistics = (hwId, filterState) => async (dispatch, getState) => {
  let resp;
  const paramsObj = {
    homeworkId: hwId,
    classrooms: filterState?.studentsClassroomFilter?.join(),
    rowsKey: filterState?.rowsFiller,
    measureKey: filterState?.measureFilter,
  }
  const params = generateGetParamsByObj(paramsObj);
  try {
    resp = await axios.get(`${HOMEWORK_EXECUTION_STATISTICS}${params}`);
  } catch (error) {
    console.log('debug classrooms list error', error);
  }
  return resp
};
export const fetchCommonDynamicsStatistics = (filterState) => async (dispatch, getState) => {
  let resp;
  const periodsWithFragmentation = getDateByPeriod(
    {
      start: filterState?.periodFillerDate?.start,
      end: filterState?.periodFillerDate?.end
    },
    filterState?.periodFillerFragmentation
  ).map(el => el.date.format('YYYY-MM-DD'));

  const paramsObj = {
    periods: periodsWithFragmentation.join(),
    periodDateStart: filterState?.periodFillerDate?.start,
    periodDateEnd: filterState?.periodFillerDate?.end,
    periodFragmentation: filterState?.periodFillerFragmentation,
    classrooms: filterState?.studentsClassroomFilter?.join(),
    rowsKey: filterState?.rowsFiller,
    measureKey: filterState?.measureFilter,
  }
  const params = generateGetParamsByObj(paramsObj);
  try {
    resp = await axios.get(`${HOMEWORK_COMMON_DYNAMICS_STATISTICS}${params}`);
  } catch (error) {
    console.log('debug classrooms list error', error);
  }
  return resp
};
export const fetchCommonProgressStatistics = (filterState) => async (dispatch, getState) => {
  let resp;

  const paramsObj = {
    chapterId: filterState?.chapterFilter,
    classrooms: filterState?.studentsClassroomFilter?.join(),
    rowsKey: filterState?.rowsFiller,
    measureKey: filterState?.measureFilter,
  }
  const params = generateGetParamsByObj(paramsObj);
  try {
    resp = await axios.get(`${HOMEWORK_COMMON_PROGRESS_STATISTICS}${params}`);
  } catch (error) {
    console.log('debug classrooms list error', error);
  }
  return resp
};

export const fetchHomeworksByPeriod = (period) => async (dispatch, getState) => {
  let resp;

  const paramsObj = {
    periodStart: period.start,
    periodEnd: period.end,
  }
  const params = generateGetParamsByObj(paramsObj);
  try {
    resp = await axios.get(`${HOMEWORKS_BY_PERIOD}${params}`);
  } catch (error) {
    console.log('debug classrooms list error', error);
  }
  return resp
};

export const fetchTeacherCard = (teacherCardById) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.get(`${TEACHER_CARD}?id=${teacherCardById}`);
  } catch (error) {
    console.log('debug classrooms list error', error);
  }
  return resp
};


export const setUserWithMode = (user, profile) => async (dispatch) => {
  const userReduxFormat = {...profile, ...user, profile: profile.id};
  dispatch(setUser(userReduxFormat))
  const isDarkMode = profile.role === userRoles.student;
  dispatch(setDarkMode(isDarkMode));
}



// ---------- COMMON ----------


export const SET_ADD_NEW_HOMEWORK_FLAG = 'SET_ADD_NEW_HOMEWORK_FLAG';
export const setAddNewHomeworkFlag = (status) => {
  return {
    type: SET_ADD_NEW_HOMEWORK_FLAG,
    payload: status,
  }
};

export const CHANGE_CREATE_LESSON_SPINNER_VISIBLE = 'CHANGE_CREATE_LESSON_SPINNER_VISIBLE';
export const changeCreateLessonSpinnerVisible = (visible) => {
  return {
    type: CHANGE_CREATE_LESSON_SPINNER_VISIBLE,
    payload: visible,
  }
};


export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const changeLanguage = (lang) => {
  return {
    type: CHANGE_LANGUAGE,
    payload: lang,
  }
};

export const SET_USER = 'SET_USER';
export const setUser = (user) => {
  return {
    type: SET_USER,
    payload: user,
  }
};
export const LOGGED_USER = 'LOGGED_USER';
export const setLoggedUser = (status) => {
  return {
    type: LOGGED_USER,
    payload: {status},
  }
};

export const SET_TEACHER_PROFILE = 'SET_TEACHER_PROFILE';
export const setTeacherProfile = (teacherProfile) => {
  return {
    type: SET_TEACHER_PROFILE,
    payload: teacherProfile,
  }
};

export const SET_LESSONS = 'SET_LESSONS';
export const setLessons = (lessons) => {
  return {
    type: SET_LESSONS,
    payload: lessons,
  }
};

export const SET_ACTIVE_LESSON = 'SET_ACTIVE_LESSON';
export const setActiveLesson = (lesson) => {
  return {
    type: SET_ACTIVE_LESSON,
    payload: lesson,
  }
};

export const SET_ACTIVE_LESSON_VARIANT = 'SET_ACTIVE_LESSON_VARIANT';
export const setLessonVariant = (variant) => {
  return {
    type: SET_ACTIVE_LESSON_VARIANT,
    payload: variant,
  }
};

export const UPDATE_ACTIVE_LESSON = 'UPDATE_ACTIVE_LESSON';
export const updateActiveLesson = (lessonData) => {
  return {
    type: UPDATE_ACTIVE_LESSON,
    payload: lessonData,
  }
};

export const SET_LESSONS_GROUPS = 'SET_LESSONS_GROUPS';
export const setLessonsGroups = (lessonsGroup) => {
  return {
    type: SET_LESSONS_GROUPS,
    payload: lessonsGroup,
  }
};

export const SET_CHAPTERS = 'SET_CHAPTERS';
export const setChapters = (chapters) => {
  return {
    type: SET_CHAPTERS,
    payload: chapters,
  }
};

export const SET_LESSONS_ACCESS = 'SET_LESSONS_ACCESS';
export const setLessonsAccess = (lessonsAccess) => {
  return {
    type: SET_LESSONS_ACCESS,
    payload: lessonsAccess,
  }
};

export const RESET_TO_INIT = 'RESET_TO_INIT';
export const resetToInit = () => {
  return {
    type: RESET_TO_INIT,
    payload: {},
  }
};

export const CHANGE_LESSON_SESSION = 'CHANGE_LESSON_SESSION';
export const changeLessonSession = (lessonSession) => {
  return {
    type: CHANGE_LESSON_SESSION,
    payload: lessonSession,
  }
};
export const RESET_LESSON_SESSION = 'RESET_LESSON_SESSION';
export const resetLessonSession = () => {
  return {
    type: RESET_LESSON_SESSION,
    payload: {},
  }
};
export const SET_VIDEOS = 'SET_VIDEOS';
export const setVideos = (videos) => {
  return {
    type: SET_VIDEOS,
    payload: videos,
  }
};


// ----------------- PRESENTATION -----------------
export const CHANGE_PRESENTATION_IMG = 'CHANGE_PRESENTATION_IMG';
export const changePresentationImg = (index) => {
  return {
    type: CHANGE_PRESENTATION_IMG,
    payload: index,
  }
};
export const SET_PRESENTATION_DATA = 'SET_PRESENTATION_IMGS';
export const setPresentationData = (imgs, allPresentations) => {
  return {
    type: SET_PRESENTATION_DATA,
    payload: {imgs, allPresentations},
  }
};
export const SET_PRESENTATION = 'SET_PRESENTATION';
export const setPresentation = (presentation) => {
  return {
    type: SET_PRESENTATION,
    payload: presentation,
  }
};
export const CHANGE_PRESENTATION_VISIBLE_TO_STUDENTS = 'CHANGE_PRESENTATION_VISIBLE_TO_STUDENTS';
export const changePresentationVisibleToStudents = (visible) => {
  return {
    type: CHANGE_PRESENTATION_VISIBLE_TO_STUDENTS,
    payload: visible,
  }
};
export const CHANGE_ACTIVE_PRESENTATION = 'CHANGE_ACTIVE_PRESENTATION';
export const changeActivePresentation = (presentationId) => {
  return {
    type: CHANGE_ACTIVE_PRESENTATION,
    payload: presentationId,
  }
};


// ------------------ TEXT PROBLEMS ------------------

export const fetchTextProblemsContainer = (lsnCode, variantId) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.get(`${TEXT_PROBLEMS_CONTAINER}?code=${lsnCode}${variantId ? '&variantId=' + variantId : ''}`);
  } catch (error) {
    console.log('debug text problem error', error);
  }
  return resp
};

export const textProblemAnswerCleaner = (lessonCodesList) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.post(`${TEXT_PROBLEM_RESULT_CLEANER}`, {lessonCodesList});
  } catch (error) {
    openNotificationWithIcon('error', 'Произошла ошибка');
    return
  }
  return resp;
};

export const postTextProblemAnswer = (problem_id, userAnswers, optionId, variantId) => async (dispatch, getState) => {
  const currentState = getState();
  let resp;
  let data = {problem_id, userAnswers};
  if (variantId) {
    data.variantId = variantId;
  }
  try {
    resp = await axios.post(`text-problem-answer/`, data);
  } catch (error) {
    console.log('debug text problem answer error', error);
  }
  return resp
};


// -------------------- HOMEWORK -------------------------

export const fetchHomework = (isTeacher, homeworkId) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.get(`${HOMEWORK}?isTeacher=${Number(isTeacher)}${homeworkId ? '&homeworkId='+homeworkId : ''}`);
  } catch (error) {}

  if (resp?.status && resp?.homeworks) {
    const homeworks = resp.homeworks;
    if (homeworks) {
      dispatch(setHomeworks(homeworks));
      return homeworks;
    }
  }
  return resp;
};

export const fetchHwTeacherNotifications = () => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.get(`${HW_TEACHER_NOTIFICATIONS}`);
  } catch (error) {
  }
  if (resp?.status) {
    dispatch(setNotifications(resp.results))
  }
  return resp;
};

export const createHomework = (title, lsnId) => async (dispatch, getState) => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const defaultTitle = `Задание ${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}`;
  const corrTitle = title || defaultTitle;
  let resp;
  try {
    resp = await axios.post(`${HOMEWORK}`, {title: corrTitle, lsnId});
  } catch (error) {
    openNotificationWithIcon('error', 'Произошла ошибка');
  }
  if (resp && resp.status) {
    const homeworks = resp.homeworks;
    if (homeworks) {
      dispatch(setHomeworks(homeworks));
    }
    openNotificationWithIcon('success', 'ДЗ успешно создано');
  }
  return resp;
};

export const joinToHomework = (firstName, lastName, hwCode) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.post(`${HOMEWORK_AUTH}`, {firstName, lastName, hwCode});
  } catch (error) {
    openNotificationWithIcon('error', 'Произошла ошибка');
    return
  }
  if (resp?.status) {
    dispatch(setUserWithMode(resp.user, resp.profile));
  }
  return resp;
};

export const completeStudentRegistration = (email, password) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.patch(`${HOMEWORK_AUTH}`, {email, password});
  } catch (error) {
    openNotificationWithIcon('error', 'Произошла ошибка');
    return
  }
  if (resp?.status) {
    dispatch(setUserWithMode(resp.user, resp.profile));
  }
  return resp;
};

export const updateHomework = ({hwId, title, lsnId, action, teacherSeenHw}) => async (dispatch, getState) => {
  /**
   * action?: 'add' | 'remove' - for task/lesson  | undefined  - for update title
   */

  let resp;
  try {
    resp = await axios.patch(`${HOMEWORK}`, {hwId, title, lsnId, action, teacherSeenHw});
  } catch (error) {
    openNotificationWithIcon('error', 'Произошла ошибка при добавлении задания к ДЗ');
  }
  if (resp && resp.status) {
    const homeworks = resp.homeworks;
    if (homeworks) {
      dispatch(setHomeworks(homeworks));
    }
    let msg = 'ДЗ успешно обновлено';
    if (title) {
      msg = 'Название ДЗ успешно изменено';
    }
    if (lsnId) {
      if (action === 'add') {
        msg = 'Задача успешно добавлена в ДЗ';
      }
      if (action === 'remove') {
        msg = 'Задача успешно удалена из ДЗ';
      }
    }

    if (!teacherSeenHw) {
      openNotificationWithIcon('success', msg);
    }
  }
  return resp;
};


export const removeHomework = (hwId) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.delete(`${HOMEWORK}`, {data: {hwId}});
  } catch (error) {
    openNotificationWithIcon('error', 'Произошла ошибка при добавлении задания к ДЗ');
  }
  if (resp && resp.status) {
    const homeworks = resp.homeworks;
    if (homeworks) {
      dispatch(setHomeworks(homeworks));
    }
    openNotificationWithIcon('success', 'ДЗ успешно удалено');
  }
  return resp;
};


export const getHomeworkStats = (homeworkId) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.get(`${HOMEWORK_STATS}?homework_id=${homeworkId}`);
  } catch (error) {
  }
  if (resp && resp.status) {
    return resp.statistics;
  }
  return undefined;
};

export const SET_SELECTED_HOMEWORK_ID = 'SET_SELECTED_HOMEWORK_ID';
export const setSelectedHomeworkId = (homeworkId) => {
  return {
    type: SET_SELECTED_HOMEWORK_ID,
    payload: homeworkId,
  }
};

export const SET_HOMEWORKS = 'SET_HOMEWORKS';
export const setHomeworks = (homeworks) => {
  return {
    type: SET_HOMEWORKS,
    payload: {homeworks},
  }
};
export const SET_HOMEWORK_NOTIFICATIONS = 'SET_HOMEWORK_NOTIFICATIONS';
export const setNotifications = (notifications) => {
  return {
    type: SET_HOMEWORK_NOTIFICATIONS,
    payload: notifications,
  }
};


// ---------------- RESULTS ----------------

export const addLessonResult = (
  lesson_code, success = false, detailed = {}, create_new = false, variant, failure
) => async (dispatch, getState) => {
  const currentState = getState();
  const homeworkId = currentState.lessonsReducer.selectedHomeworkId;
  let resp;
  try {
    resp = await axios.post(`${LESSON_RESULT}`, {lesson_code, homeworkId, success, detailed, create_new, variant, failure});
  } catch (error) {
    openNotificationWithIcon('error', 'Произошла ошибка');
    return
  }
  return resp;
};

export const getLessonResult = (lesson_code) => async (dispatch, getSate) => {
  let resp;
  try {
    resp = await axios.get(`${LESSON_RESULT}?lesson_code=${lesson_code}`);
  } catch (error) {
    openNotificationWithIcon('error', 'Произошла ошибка');
    return
  }
  return resp;
};


export const fetchPresentationById = (presentationId) => async (dispatch, getSate) => {
  let resp;
  try {
    resp = await axios.get(`${PRESENTATION}${presentationId}`);
  } catch (error) {
    openNotificationWithIcon('error', 'Произошла ошибка');
    return
  }
  return resp;
};


export const fetchLessonsCatalog = ({
                                      isTeacherCardCatalog,
                                      selectedParagraphId,
                                      selectedChapterId,
                                      selectedGradeNumber,
                                      nextUrl
                                    }) => async (dispatch, getSate) => {
  let resp;
  const paramsObj = {
    groupId: selectedParagraphId,
    isTeacherCardCatalog: isTeacherCardCatalog || undefined,
    chapterId: selectedChapterId,
    gradeNumber: selectedGradeNumber,
  };
  let params = generateGetParamsByObj(paramsObj, Boolean(nextUrl));
  try {
    resp = await axios.get(`${nextUrl || LESSON_CATALOG}${params}`);
  } catch (error) {
    // openNotificationWithIcon('error', 'Произошла ошибка при получении списка уроков');
    return
  }
  return resp;
};

export const fetchLessonsParagraphsForCatalog = () => async (dispatch, getSate) => {
  let resp;
  try {
    resp = await axios.get(`${LESSONS_GROUPS}`);
  } catch (error) {
    openNotificationWithIcon('error', 'Произошла ошибка при получении глав и параграфов');
    return
  }
  if (resp?.status) {
    dispatch(setChapters(resp.chapters))
    dispatch(setLessonsGroups(resp.lessonsGroups))
  }
  return resp;
};





