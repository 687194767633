import {Link, useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {defaultHtmlShadowStyles, layout2} from "../../../../utils/styles";
import LessonTag from "./LessonTag";
import {TagsList} from "../../../../utils/common";
import AddHomeworkBtn from "./AddHomeworkBtn";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import LessonItemStatistic from "./LessonItemStatistic";
import {CaretRightOutlined} from '@ant-design/icons';
import {serverMediaUrl} from "../../../../utils/api";
import {Tooltip} from "antd";
import {goToTaskUrlGenerator} from "../utils";


const styles = {
  lessonItem: {
    color: layout2.blue,
    backgroundColor: 'rgba(255,255,255,0.35)',
    position: 'relative',
  },
  selected: {
    borderColor: layout2.blue2,
    color: 'white',
    boxShadow: '0px 3px 10px 1px rgba(0,0,0,0.2)',
  }
};

const Tags = (props) => {
  const {tagsList, direction, getTagItem} = props;
  const alignLeft = direction === 'left'
  const sideStyle = alignLeft ? {left: 0} : {right: 0};
  return (
    <div className={props.tagsContainerClassname} style={{position: 'absolute', top: -18, display: 'flex', ...sideStyle}}>
      {
        tagsList.map((item, i) => {
          const tagItem = getTagItem(item);
          return (
            <React.Fragment key={`tag-${item.id}`}>
              <LessonTag align={alignLeft ? 'left' : 'right'} tagItem={tagItem} indx={i} itemsCount={tagsList?.length || 0}/>
            </React.Fragment>
          )
        })
      }
    </div>
  )
}

const LessonItem = (props) => {
  const {
    lessonItem, selectedLesson, setSelectedLesson, selectedHwId, homeworks,
    user, isTeacherHwPage, teacherPage, getLessonResult, getHomeworkStats,
  } = props;
  const {title, code, description} = lessonItem;
  const history = useHistory();

  const [lessonResult, setLessonResult] = useState({});
  const [homeworkStats, setHomeworkStats] = useState({});

  const selectedId = selectedLesson?.id;
  useEffect( () => {
    if (selectedLesson?.id === lessonItem.id) {
      getLessonResult(code)
        .then(result => {setLessonResult(result.lesson_result)})
        .catch(error => console.log('debug on lesson result error', error))

      if (selectedHwId) {
        getHomeworkStats(selectedHwId).then(stats => {
          if (stats?.by_lesson?.[code]) {
            setHomeworkStats( stats?.by_lesson?.[code] || {});
          }
        })
      }
    }
  }, [selectedId]);

  const extraStyles = selectedLesson?.id === lessonItem.id ? styles.selected : {};
  let combinedStyles = {...styles.lessonItem, ...extraStyles};

  const addedHomeworks = (homeworks || []).reduce((accum, hw) => {
    const isAdded = hw.tasks.find(tsk => tsk.id === lessonItem.id);
    if (isAdded) {
      accum.push(hw);
    }
    return accum
  }, []);

  const selectedItem = lessonItem?.id === selectedLesson?.id;
  const studentHwPage = !teacherPage && (teacherPage !== undefined);
  const infoVisible = selectedItem && studentHwPage;
  const lessonLink = goToTaskUrlGenerator(code, selectedHwId, history);

  const {thumbnail_small, thumbnail, thumbnail_big, img_gif} = (lessonItem || {});

  let image = (thumbnail_small || thumbnail) ? serverMediaUrl+(thumbnail_small || thumbnail || '') : undefined;
  if (props.newCardStyle) {
    image = (img_gif || thumbnail_big || thumbnail || thumbnail_small)
      ? serverMediaUrl+(img_gif || thumbnail_big || thumbnail || thumbnail_small || '')
      : undefined;
  }
  return (
    <div className={'lesson-item-container'} style={{margin: '40px 0', ...(infoVisible ? defaultHtmlShadowStyles : {})}}>
      <div
        id={`lsn-${lessonItem?.id}`}
        className={'lsnMainContainer'}
        style={combinedStyles}
        onClick={(e) => {
          setSelectedLesson(lessonItem?.id === selectedLesson?.id ? undefined : lessonItem);
        }}
      >
        <Tags
          key={'lsn-tags'}
          tagsList={lessonItem.tags}
          direction={'left'}
          getTagItem={item => {
            const tagKey = item.code.toLowerCase();
            return {...TagsList[tagKey], ...item};
          }}
          tagsContainerClassname={'task-types-tags'}
        />
        {
          !props.withoutHwTags && teacherPage && (
            <Tags
              key={'hw-tags'}
              direction={'right'}
              tagsList={addedHomeworks}
              tagsContainerClassname={'homeworks-tags'}
              getTagItem={item => ({...TagsList['homework'], ...{title: item.title}})}
            />
          )
        }

        <div className={'lsnThumbContainer'}>
          <img
            src={image}
            className={'lsnThumbImg'}
            style={((thumbnail || thumbnail_small) && (!img_gif || thumbnail_big)) ? {width: '50px', height: '50px'}:{}}
          />
        </div>
        <div className={'lsnContainerLeft'}>
          <div className={'lsnTitle'} style={{color: selectedItem ? layout2.blue2 : 'black'}}>{title}</div>
        </div>
        <div className={'lsnContainerRight'}>
          <div className={'lsnPlayImgWrapper'} onClick={(e) => e.stopPropagation()}>
            <div style={{display: 'flex',  justifyContent: 'space-between'}}>
              {
                (teacherPage || !user) && (
                  <Tooltip placement="top" title={isTeacherHwPage ? 'Удалить из проверочной работы' : 'Добавить в проверочную работу'}>
                    <div>
                      <AddHomeworkBtn isAnonym={!user} teacherHwPage={isTeacherHwPage} selectedHwId={selectedHwId} taskId={lessonItem.id}/>
                    </div>
                  </Tooltip>
                )
              }
              <Tooltip placement="top" title={'Открыть задачу'}>
                <Link className={'lsnItemPlayIconWrapper'} to={lessonLink}>
                  <CaretRightOutlined className={'lsnItemPlayIcon'}/>
                </Link>
              </Tooltip>
            </div>
          </div>
        </div>

      </div>
      {
        infoVisible && lessonResult?.success && (
          <LessonItemStatistic lsn={lessonItem} lessonResult={lessonResult} homeworkStats={homeworkStats}/>
        )
      }
    </div>
  )
};



const mapStateToProps = (state, {match}) => {
  return {
    selectedHomeworkId: state.lessonsReducer.selectedHomeworkId,
    homeworks: state.lessonsReducer.homeworks,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLessonResult: (lesson_code) => dispatch(actions.getLessonResult(lesson_code)),
    getHomeworkStats: (homeworkId) => dispatch(actions.getHomeworkStats(homeworkId)),
    setLessonVariant: (variant) => dispatch(actions.setLessonVariant(variant)),
  }
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LessonItem);
