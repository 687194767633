import React, {createRef, useEffect, useRef, useState} from "react";
import {layout2} from "../../../../utils/styles";
import '../styles/header.css';
import logoAppInventic from "../../../../platformImages/layout2/logo.png"
import logoWhite from "../../../../platformImages/layout2/logoWhite.png"
import EdEraLogo from "../../../../platformImages/layout2/EdEraLogo.png"
import axios from "axios";
import {SUBSCRIBE} from "../../../../store/urls";
import {Avatar, Button, Badge} from "antd";
import {MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined} from '@ant-design/icons';
import useOutsideClick from "../../../../utils/custom-hooks/useOutsideClick";
import Menu from "./Menu";
import {useHistory} from "react-router-dom";
import {howToUsePlatformForTeacher} from "../../../../utils/common";
import useMedia from "../../../../utils/custom-hooks/useMedia";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";

const styles = {
  userName: {
    color: layout2.blue,
    fontWeight: 'bold'
  },
  premiumButton: {
    backgroundColor: layout2.orange
  }
};

const Header = ({
    user,
    pageKey,
    mobileMode,
    isDarkMode,
    logoutAction,
    descriptionVisible,
    notificationsCount,
    sidebarMenuVisible,
    setSidebarMenuVisible,
    setFeedbackPopupVisible,
    menuChildrenExist,
  }) => {
  const [mainMenuVisible, setMainMenuVisible] = useState(false);
  const [popupMenuVisible, setPopupMenuVisible] = useState(false);

  const adaptiveMenuHidden = useMedia(['(min-width: 1200px)'],[true], false);
  const isSmallMobile = useMedia(['(max-width: 500px)'],[true], false);


  useEffect(() => {
    if (descriptionVisible || adaptiveMenuHidden) {
      setMainMenuVisible(false);
    }
  }, [descriptionVisible, adaptiveMenuHidden])

  const history = useHistory();
  const containerPopupRef = createRef(null);

  useOutsideClick(containerPopupRef, () => {
    if (popupMenuVisible) {
      setPopupMenuVisible(false);
    }
  });

  const isTeacher = user?.role === 'teacher';
  const isEdEra = window.location.host.toLowerCase().includes('edera');

  let logo = logoAppInventic;
  if (isEdEra) {
    logo = EdEraLogo;
  }
  if (isDarkMode && !isEdEra) {
    logo = logoWhite;
  }



  const PopupMenu = (props) => {
    let menu = [
      {
        id: 'feedback',
        title: 'Обратная связь',
        action: () => {
          setFeedbackPopupVisible(true);
        }
      },
      {
        id: 'logout',
        title: 'Выйти',
        action: () => {
          logoutAction();
          history.push('/login');
        }
      },
    ]

    if (isTeacher) {
      menu = [
        {
          id: 'FAQ',
          title: 'Как пользоваться платформой',
          action: howToUsePlatformForTeacher
        },
        ...menu
      ];
      // if (notificationsCount) {
      //   menu = [{
      //     id: 'hw-notification', title: 'Перейти к обновлениям', action: () => {
      //       history.push('/')
      //     }, notificationColor: '#FF9737'
      //   }, ...menu];
      // }
    }
    if (isSmallMobile) {
      menu = [{ id: 'username', title: ` ${user.first_name} ${user.last_name}`}, ...menu]
    }

    return (
      <div className={'header-popUpMenu'} style={{display: popupMenuVisible ? 'block' : 'none'}}>
        {
          menu.map(el => {
            let itemClass = `header-popUpMenu__item`;
            if (el.id === 'username') {
              itemClass += ' header-popUpMenu__item--name';
            }

            return (
              <div key={el.id} className={'header-popUpMenu__item-wrapper'}>
                <Badge color={el.notificationColor} offset={[0, 10]}>
                  <div
                    onClick={el.action}
                    style={{color: layout2.blue}}
                    className={itemClass}
                  >{el.title}</div>
                </Badge>
              </div>
            )
          })
        }
      </div>
    )
  }

  const userNamePopupActiveBg = isDarkMode ? '#294C85' : layout2.blueBg;

  return (
    <div id={'header'} className={`header`}>
      <div className={'header__container'}>

        {/* Если нет меню на стр. то не показывает кнопку меню */}
        {
          menuChildrenExist && (
            <Button
              className={'header__menu-btn'}
              onClick={() => setSidebarMenuVisible(!sidebarMenuVisible)}
            >
              Темы
              {
                sidebarMenuVisible
                  ? <MenuFoldOutlined />
                  : <MenuUnfoldOutlined />
              }
            </Button>

          )
        }

        <div className={'header__left'}>
          <img className={'header__logo'} src={logo}/>

          <Button
            className={'header__main-menu-btn'}
            // type={'ghost'}
            type={'primary'}
            onClick={() => setMainMenuVisible(!mainMenuVisible)}
          >{!mainMenuVisible ? 'Меню' : 'Скрыть меню'}</Button>

          <Menu user={user} pageKey={pageKey} />
        </div>
        <div className={'header__right'}>
          {
            user?.id ? (
              <div className={'header__popupMenuWrapper'} ref={containerPopupRef}>
                <div
                  className={`header__userName`}
                  style={{...styles.userName, backgroundColor: popupMenuVisible ? userNamePopupActiveBg : 'transparent'}}
                  onClick={() => setPopupMenuVisible(!popupMenuVisible)}
                >
                  <div style={{display: 'flex', alignItems: 'center', whiteSpace: 'nowrap'}}>
                    <Badge
                      title={'Появились обновления в \nдомашних заданиях'}
                      offset={[-45, 5]}
                      overflowCount={99}
                      count={notificationsCount}
                      style={{ backgroundColor: '#FF9737', zIndex: 10 }}
                    >
                      <Avatar icon={<UserOutlined />} className={'header__userAvatar'}/>
                    </Badge>
                    {
                      !isSmallMobile && (
                        <span>
                          {` ${user.first_name} ${user.last_name}`}
                        </span>
                      )
                    }
                  </div>
                </div>

                <PopupMenu />
              </div>
            ) : (
              <div className={'header__popupMenuWrapper'} ref={containerPopupRef}>
                <div
                  className={`header__userName`}
                >
                  <a href={'/login'} style={{color: layout2.blue}}>Войти</a>
                </div>
              </div>
            )
          }
        </div>
      </div>

      <Menu user={user} pageKey={pageKey} isDarkMode={isDarkMode} visible={mainMenuVisible} mobile={true} />
    </div>
  );
};

export default Header;
