import React, {useEffect, useState} from "react";
import Universal1Task from "../../pages/schoolTasks/cotainers/Universal1Task";
import Example1Task from "../../pages/schoolTasks/cotainers/Example1Task";
import Example2Task from "../../pages/schoolTasks/cotainers/Example2Task";
import Example3Task from "../../pages/schoolTasks/cotainers/Example3Task";
import Example4Task from "../../pages/schoolTasks/cotainers/Example4Task";
import Example5Task from "../../pages/schoolTasks/cotainers/Example5Task";
import Example6Task from "../../pages/schoolTasks/cotainers/Example6Task";
import Example7Task from "../../pages/schoolTasks/cotainers/Example7Task";
import Example8Task from "../../pages/schoolTasks/cotainers/Example8Task";
import Example9Task from "../../pages/schoolTasks/cotainers/Example9Task";
import Example10Task from "../../pages/schoolTasks/cotainers/Example10Task";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import SchoolTaskAdmin from "../../pages/schoolTasks/cotainers/SchoolTaskAdmin";
import LogicWheel1 from "../../pages/schoolTasks/cotainers/LogicWheel1";
import LogicWheel2 from "../../pages/schoolTasks/cotainers/LogicWheel2";
import LogicWheel3 from "../../pages/schoolTasks/cotainers/LogicWheel3";
import LogicWheel4 from "../../pages/schoolTasks/cotainers/LogicWheel4";
import LogicWheel5 from "../../pages/schoolTasks/cotainers/LogicWheel5";
import LogicWheel6 from "../../pages/schoolTasks/cotainers/LogicWheel6";
import Bicycle3 from "../../pages/schoolTasks/cotainers/Bicycle3";
import GreeceBlocks6 from "../../pages/schoolTasks/cotainers/GreeceBlocks6";
import GreeceBlocks1 from "../../pages/schoolTasks/cotainers/GreeceBlocks1";
import GreeceBlocks2 from "../../pages/schoolTasks/cotainers/GreeceBlocks2";
import GreeceBlocks3 from "../../pages/schoolTasks/cotainers/GreeceBlocks3";
import GreeceBlocks4 from "../../pages/schoolTasks/cotainers/GreeceBlocks4";
import GreeceBlocks5 from "../../pages/schoolTasks/cotainers/GreeceBlocks5";
import LogicLever1 from "../../pages/schoolTasks/cotainers/LogicLever1";
import LogicLever2 from "../../pages/schoolTasks/cotainers/LogicLever2";
import LogicLever3 from "../../pages/schoolTasks/cotainers/LogicLever3";
import LogicLever4 from "../../pages/schoolTasks/cotainers/LogicLever4";
import LogicLever5 from "../../pages/schoolTasks/cotainers/LogicLever5";
import LogicLever6 from "../../pages/schoolTasks/cotainers/LogicLever6";
import ChooseSomeItemsTask from "../../pages/schoolTasks/cotainers/ChooseSomeItemsTask";
import LogicWedge1 from "../../pages/schoolTasks/cotainers/LogicWedge1";
import LogicWedge2 from "../../pages/schoolTasks/cotainers/LogicWedge2";
import LogicWedge3 from "../../pages/schoolTasks/cotainers/LogicWedge3";
import LogicWedge4 from "../../pages/schoolTasks/cotainers/LogicWedge4";
import LogicWedge5 from "../../pages/schoolTasks/cotainers/LogicWedge5";
import LogicWedge6 from "../../pages/schoolTasks/cotainers/LogicWedge6";


const SchoolTaskContainer = (props) => {
  const [windowChanged, setWindowChanged] = useState(0);

  const resizeListener = () => setWindowChanged(windowChanged + 1);

  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener)
    };
  }, []);

  const tasks = [
    { key: 'universal_task1', task: <Universal1Task lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'school_task1', task: <Example1Task lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'school_task2', task: <Example2Task lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'school_task3', task: <Example3Task lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'school_task4', task: <Example4Task lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'school_task5', task: <Example5Task lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'school_task6', task: <Example10Task lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'school_task7', task: <Example7Task lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'school_task8', task: <Example8Task lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'school_task9', task: <Example9Task lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'school_task10', task: <Example6Task lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },

    { key: 'logic_wheel_1', task: <LogicWheel1 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'logic_wheel_2', task: <LogicWheel2 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'logic_wheel_3', task: <LogicWheel3 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'logic_wheel_4', task: <LogicWheel4 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'logic_wheel_5', task: <LogicWheel5 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'logic_wheel_6', task: <LogicWheel6 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },

    { key: 'greece_blocks1', task: <GreeceBlocks1 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'greece_blocks2', task: <GreeceBlocks2 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'greece_blocks3', task: <GreeceBlocks3 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'greece_blocks4', task: <GreeceBlocks4 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'greece_blocks5', task: <GreeceBlocks5 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'greece_blocks6', task: <GreeceBlocks6 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },

    { key: 'bicycle3', task: <Bicycle3 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },

    { key: 'logic_lever_1', task: <LogicLever1 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'logic_lever_2', task: <LogicLever2 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'logic_lever_3', task: <LogicLever3 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'logic_lever_4', task: <LogicLever4 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'logic_lever_5', task: <LogicLever5 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'logic_lever_6', task: <LogicLever6 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },

    { key: 'logic_wedge1', task: <LogicWedge1 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'logic_wedge2', task: <LogicWedge2 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'logic_wedge3', task: <LogicWedge3 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'logic_wedge4', task: <LogicWedge4 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'logic_wedge5', task: <LogicWedge5 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
    { key: 'logic_wedge6', task: <LogicWedge6 lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },

    { key: 'choose_some_items_1', task: <ChooseSomeItemsTask lessonData={props.lessonData} lessonCode={props.lessonData.code}/> },
  ]

  let corrTask = tasks.find(task => task.key === props.lessonData.code)?.task;


  if (!corrTask) {
    corrTask = <SchoolTaskAdmin lessonData={props.lessonData}/>
  }
  return (
    <div>
      { corrTask }
    </div>
  )
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  fetchSchoolTask: (containerId) => dispatch(actions.fetchSchoolTask(containerId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SchoolTaskContainer);
