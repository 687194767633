
export const FEEDBACK = 'feedback/';

export const LESSON_CATALOG = 'lesson-catalog/';
export const LESSONS_GROUPS = 'lessons-groups/';

export const CURRENT_USER = 'current-user/';
export const MAIN_PAGE = 'main-page/';
export const TEXT_PROBLEMS_CONTAINER = 'text-problems-container/';
export const TEXT_PROBLEM_RESULT_CLEANER = 'text-problem-answer-cleaner/';
export const LOGIN = 'login/';
export const FORGOT_PASSWORD = 'forgotPassword/';
export const LOGOUT = 'logout/';
export const REGISTRATION = 'registration/';

export const SCHOOL_TASK = 'school_task/';

export const LESSON = 'lesson/';
export const ACCESS = 'access/';

export const LESSON_RESULT = 'lesson_result/';

export const CREATE_LESSON_LINK = 'create-lesson-link/';
export const CLOSE_LESSON_SESSION = 'close-lesson-session/';
export const LESSON_SESSION_AUTH = 'lesson-session-auth/';
export const LESSON_ACTIVITY_LOG = 'lesson-activity-log/';

export const PRESENTATION = 'presentation/';
export const CREATE_PRESENTATION = 'presentation/';

export const SUBSCRIBE = 'subscribe/';

export const HOMEWORK = 'homework/';
export const HOMEWORK_STATS = 'homework_stats/';
export const HOMEWORK_AUTH = 'homework-auth/';
export const HOMEWORKS_BY_PERIOD = 'homeworks-by-period/';
export const HOMEWORK_EXECUTION_STATISTICS = 'homework-execution-statistics/';
export const HOMEWORK_COMMON_DYNAMICS_STATISTICS = 'homework-common-dynamics-statistics/';
export const HOMEWORK_COMMON_PROGRESS_STATISTICS = 'homework-common-progress-statistics/';


export const CLASSROOM = 'classroom/';
export const CLASSROOMS_LIST = 'classrooms-list/';


export const NEWS_FEED = 'news-feed/';
export const HW_TEACHER_NOTIFICATIONS = 'homework-teacher-notifications/';


export const TEACHER_CARD = 'teacher-card/';

export const HEADER_BANNER = 'header-banner/';

export const SKILLS = 'skills/';
export const USER_ACHIEVEMENTS = 'user-achievements/';
export const HOMEWORK_ACHIEVEMENTS = 'homework-achievements/';


export const MAP_PROGRESS_STATUS = 'map-progress-status/';
export const MAP_PROGRESS = 'map-progress/';
export const MAP_AUTH = 'map-auth/';
export const MAP_PAYMENT = 'map-payment/';


export const CAT_FINAL_TEST_SUCCESS = 'cat-final-test-success/';

export const CREATE_MAP_STATISTIC_HW = 'create-map-statistic-hw/';
export const CLEAR_MAP_STATISTIC_HW = 'clear-map-statistic-hw/';
export const MAP_STATISTIC = 'map-statistic/';

export const MAP_TASK_AVAILABILITY_STATUS = 'task_availability_status/';
export const MAP_TASK_RANGES = 'task_ranges/';
export const MAP_TASK_RANGE_AVAILABILITY = 'task_range_availability_for_class/';
